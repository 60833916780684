import React from 'react'
import blogClasses from '../../PMPblog.module.css'
import Container from '../../../../UI/Container/Container'
import Heading from '../../../../UI/Heading/Heading'
import posts from '../../../../../../data/blog/posts'
import SEO from '../../../../seo'
import Signature from '../Signature/Signature'

const post = posts.filter(
  post => post.link === 'project-dependencies-and-relationships'
)[0]

export default () => (
  <Container width={1060}>
    <SEO
      title={`PMP Blog | ${post.title}`}
      keywords={[
        `Project Dependencies`,
        `Finish to Start`,
        `Finish to Finish`,
        `Start to Start`,
        `Start to Finish`,
        `Mandatory Dependencies`,
        `Discretionary Dependencies`,
        `External Dependencies`,
        `Internal Dependencies`
      ]}
      description="4 Project Dependencies Explained, Precedence Diagramming Method, 4 Types of Activity Relationships"
    ></SEO>
    <Heading text={post.title} />
    <div className={blogClasses.Image}>{post.images[0]}</div>
    <br />
    <p>
      Let's start with the definition of a project dependency. It's a logical
      relationship between two activities when the completion or initiation of
      one is reliant on the completion or initiation of the other. All the tasks
      we do are linked to other tasks. They either affect other tasks or get
      affected by them.
    </p>
    <p>There are 4 types of project dependencies:</p>
    <div className={blogClasses.Image}>{post.images[1]}</div>
    <br />
    <div className={blogClasses.List}>
      <ol className={blogClasses.NoBottomMargin}>
        <li>
          <span>
            <b>Mandatory Dependencies</b>
            <p>
              Also known as <b>Hard Logic</b> or <b>Hard Dependencies</b>, these
              dependencies are contractually or legally required. <br />
              For example, you must prepare the wall before putting up the
              wallpaper, or you must design the building before the construction
              begins.
            </p>
          </span>
        </li>
        <li>
          <span>
            <b>Discretionary Dependencies</b>
            <p>
              Also known as <b>Preferred Logic</b>, <b>Preferential Logic</b> or{' '}
              <b>Soft Logic</b>, these dependencies are determined by the
              project team or organization. When there's more than one way to
              sequence activities, the team can choose one sequence over the
              other. Generally, these dependencies are established based on
              knowledge of best practices.
              <br />
              For example, when painting the walls in a house, the order in
              which the rooms are painted can be chosen depending on such
              factors as the required furniture removal or the need to use a
              particular room earlier than others.
            </p>
          </span>
        </li>
        <li>
          <span>
            <b>External Dependencies</b>
            <p>
              External dependencies are relationships between project activities
              and non-project activities. These are often outside the project
              team's control. For example, there is an external dependency
              between such activities as the delivery of construction equipment
              and the beginning of construction.
            </p>
          </span>
        </li>
        <li>
          <span>
            <b>Internal Dependencies</b>
            <span>
              <p style={{ marginBottom: -10 }}>
                These dependencies are based on the needs of the project and may
                be something the project team can control.
                <br />
                For example, develop software and test software.
                <br />
                For all project dependencies, the project team decides which
                activities are mandatory, discretionary, external and internal.
                Activities can have two dependency attributes at the same time
                such as:
              </p>
              <div className={blogClasses.List}>
                <ul className={blogClasses.NoBottomMargin}>
                  <li>
                    <span>mandatory-external dependency</span>
                  </li>
                  <li>
                    <span>mandatory-internal dependency</span>
                  </li>
                  <li>
                    <span>discretionary-external dependency</span>
                  </li>
                  <li>
                    <span>discretionary-internal dependency</span>
                  </li>
                </ul>
              </div>
            </span>
          </span>
        </li>
      </ol>
      <br />
      <div className={blogClasses.SubTitleContainer}>
        <h2 className={blogClasses.SubTitle}>
          PDM and 4 Types of Activity Relationships
        </h2>
      </div>
    </div>
    <span>
      <p style={{ marginBottom: -10 }}>
        The precedence diagramming method (PDM) is a technique used to construct
        a schedule model where activities are represented by nodes and are
        graphically linked by one or more logical relationships to demonstrate
        the sequence in which these activities are to be performed.
        <br />
        Activity relationships are closely related to project dependencies but
        it's not the same. A dependency just suggests that one activity is
        reliant on another activity but a dependency does not tell how an
        activity is reliant on another. These are 4 types of activity
        relationships (<b style={{ color: '#604f93' }}>A</b> - predecessor,{' '}
        <b style={{ color: '#604f93' }}>B</b> - successor):
      </p>
      <div className={blogClasses.List}>
        <ul className={blogClasses.NoBottomMargin}>
          <li>
            <span>
              Finish-to-start (<b style={{ color: '#604f93' }}>FS</b>){' '}
              <b style={{ color: '#604f93' }}>A</b> needs to finish before{' '}
              <b style={{ color: '#604f93' }}>B</b> can start
              <div
                className={blogClasses.Image}
                style={{ marginTop: 10, width: '50%', maxHeight: 160 }}
              >
                {post.images[2]}
              </div>
            </span>
          </li>
          <li>
            <span>
              Finish-to-finish (<b style={{ color: '#604f93' }}>FF</b>){' '}
              <b style={{ color: '#604f93' }}>A</b> must finish before{' '}
              <b style={{ color: '#604f93' }}>B</b> can finish
              <div
                className={blogClasses.Image}
                style={{ marginTop: 10, width: '50%', maxHeight: 160 }}
              >
                {post.images[3]}
              </div>
            </span>
          </li>
          <li>
            <span>
              Start-to-start (<b style={{ color: '#604f93' }}>SS</b>){' '}
              <b style={{ color: '#604f93' }}>A</b> must start before{' '}
              <b style={{ color: '#604f93' }}>B</b> can start
              <div
                className={blogClasses.Image}
                style={{ marginTop: 10, width: '50%', maxHeight: 160 }}
              >
                {post.images[4]}
              </div>
            </span>
          </li>
          <li>
            <span>
              Start-to-finish (<b style={{ color: '#604f93' }}>SF</b>){' '}
              <b style={{ color: '#604f93' }}>A</b> needs to start before{' '}
              <b style={{ color: '#604f93' }}>B</b> can finish
              <div
                className={blogClasses.Image}
                style={{ marginTop: 10, width: '50%', maxHeight: 160 }}
              >
                {post.images[5]}
              </div>
            </span>
          </li>
        </ul>
      </div>
    </span>
    <br />
    <p>
      SF relationship is rarely used, while FS is the most commonly used
      activity relationship. Two activities can have two relationships at the
      same time, however, multiple relationships between the same activities are
      not recommended. For that reason it's common to select the relationship
      with the highest impact.
    </p>
    <Signature date={post.date} author={post.author} />
  </Container>
)
